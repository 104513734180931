import alldogs from "./images/alldogs.JPG";
import logo from "./images/logo.png";
import "./App.css";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <div className="App-background-main Fill-width-80 Rounded Shadow">
                    <img src={logo} className="App-logo" alt="logo" />
                    <div className="App-background-secondary Fill-width-95">
                        <img
                            src={alldogs}
                            className="App-logo Shadow Rounded"
                            alt="logo"
                        />
                    </div>
                    <p>Coming Soon!</p>
                </div>
            </header>
        </div>
    );
}

export default App;
